/**
 * --------------------------------------------------------------------------
 * Bootstrap (v5.2.0-beta1): index.umd.js
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 * --------------------------------------------------------------------------
 */

import Alert from '../../bootstrap-5.2.0-b1/js/src/alert'
import Button from '../../bootstrap-5.2.0-b1/js/src/button'
import Carousel from '../../bootstrap-5.2.0-b1/js/src/carousel'
import Collapse from '../../bootstrap-5.2.0-b1/js/src/collapse'
import Dropdown from '../../bootstrap-5.2.0-b1/js/src/dropdown'
import Modal from '../../bootstrap-5.2.0-b1/js/src/modal'
import Offcanvas from '../../bootstrap-5.2.0-b1/js/src/offcanvas'
import Popover from '../../bootstrap-5.2.0-b1/js/src/popover'
import ScrollSpy from '../../bootstrap-5.2.0-b1/js/src/scrollspy'
import Tab from '../../bootstrap-5.2.0-b1/js/src/tab'
import Toast from '../../bootstrap-5.2.0-b1/js/src/toast'
import Tooltip from '../../bootstrap-5.2.0-b1/js/src/tooltip'

export default {
  Alert,
  Button,
  Carousel,
  Collapse,
  Dropdown,
  Modal,
  Offcanvas,
  Popover,
  ScrollSpy,
  Tab,
  Toast,
  Tooltip
}
